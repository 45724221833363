import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { ApplicationCodeEnum } from 'src/app/shared/enums/application-code.enum';
import { RegistrationStepEnum } from 'src/app/shared/enums/registration-step.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { AuthToken } from 'src/app/shared/models/authtoken.model';
import { CreateTermsAndConditions } from 'src/app/shared/models/createTermsAndConditions.model';
import { Employee } from 'src/app/shared/models/employee.model';
import { GatewayService } from 'src/app/shared/services/gateway.service';
import { GroundTruthDataService } from 'src/app/shared/services/ground-truth-data.service';
import { SiloApiService } from 'src/app/shared/services/siloApi.service';
import { SessionStateService } from 'src/app/shared/services/store/session/session-state.service';
import { SessionQuery } from 'src/app/shared/services/store/session/session.query';
import {
  CIVILITY,
  FIRSTNAME,
  LASTNAME,
  CELL_PHONE_NUMBER,
  PHONE_NUMBER,
  INFO_GREFFE_DATA,
  SIRET, EMAIL
} from '../affilie-apz/affilie-apz.component';
import {Address, ChiefExecutive, CompanyModel, CreateAdhesionRequestRequest, mailRegex, UserAffiliateCreate} from 'nit-angular-lib';
import {InfoGreffeData} from '@shared/models/infoGreffeData';
import {TranslateService} from '@ngx-translate/core';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss']
})
export class SecondStepComponent implements OnInit, OnDestroy {
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();

  private subscription = new Subscription();

  public applicationCode$: Observable<ApplicationCodeEnum>;
  public applicationId: string;
  public saml: string;

  token: AuthToken;
  success: boolean;
  callApiIsDone: boolean;
  employeeId: string;
  employee: Employee;
  cellPhonePro: string;
  mailPro: string;
  userId: number;
  email: string;
  type: string;
  isLoading: boolean;
  private TYPE_NEW_AFFILIATE = 'NEW_AFFILIATION';
  private PRODUCT_ID_APZ = 'APZ';
  private COMPENSATING_ID_BIMPLI_TR = 'CRBIMPLITR';
  private COUNTRY_DEFAULT = 'FR';
  private CODE_ERROR_DUPLICATE_LOGIN = 'ALREADY_EXIST_022';

  messageErreur: string;
  public ApplicationCodeEnum = ApplicationCodeEnum;

  constructor(
    private readonly siloApiService: SiloApiService,
    private readonly gatewayService: GatewayService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly gtds: GroundTruthDataService,
    private readonly sessionQuery: SessionQuery,
    private readonly sessionStateService: SessionStateService,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
  }

  /**
   * Validation de l'étape 2 du formulaire
   */

  submit(form, typeLogin?, employeeId?) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.messageErreur = '';
    if (employeeId !== null && employeeId !== undefined) {
      this.employeeId = employeeId;
    }
    this.gatewayService.getAccessToken().subscribe((authToken) => {
      this.token = authToken;
      if ( this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
        this.createUserAffiliate(form);
      } else {
        this.createUserAccount(form, typeLogin);
      }
    });
  }

  private createUserAccount(form, typeLogin?){
    let type = '';
    if (!form.login || form.login === this.mailPro) {
      type = 'EMAIL_PRO';
      form.login = this.mailPro;
    } else if (mailRegex.test(form.login)) {
      // si c'est un email different de l'email pro alors c'est l'email perso
      type = 'EMAIL_PERSO';
    } else {
      // si ce n'est pas un mail c'est un n° tel
      type = 'CELL_PHONE_PERSO';
    }
    this.reCaptchaV3Service.execute('CreateAccountAction').subscribe((valueCaptcha) => {
      this.siloApiService
        .createUserAccount(
          form.login,
          form.password,
          type,
          this.token,
          this.employeeId,
          this.applicationId,
          valueCaptcha,
          form.phone,
          form.secretQuestion,
          form.response
        )
        .subscribe(
          (userId) => this.createTermsAndConditionsAcceptances(userId),
          (error) => {
            this.treatError(error);
          }
        );
    });
  }

  private createTermsAndConditionsAcceptances(userId) {
    this.userId = userId.id;
    localStorage.setItem('id', this.userId.toString());

    let profileTypeLocal : string;
    if (this.applicationId === ApplicationCodeEnum.AFFILIE_APZ) {
      profileTypeLocal = 'AFFILIATE';
    } else {
      profileTypeLocal = 'EMPLOYEE';
    }
    const body: CreateTermsAndConditions = {
      profileType: profileTypeLocal,
      applicationId: this.applicationId
    };
    this.siloApiService
      .createTermsAndConditionsAcceptances(userId.id, body,  this.token)
      .subscribe(
        () => {
          this.createAdhesionRequest();
        },
        (error) => {
          this.treatError(error);
        }
      );
  }

  private treatError(error) {
    this.success = false;
    this.isLoading = false;
    this.callApiIsDone = true;
    if (error.error.errors === undefined) {
      this.messageErreur = error.message;
    } else {
      if (error.error.errors[0].code === this.CODE_ERROR_DUPLICATE_LOGIN) {
        this.messageErreur = this.translate.instant('AFFILIE_APZ.CREATION_ACCOUNT.ERROR.LOGIN_ALREADY_EXIST');
      } else {
        this.messageErreur = error.error.errors[0].message;
      }
    }
    this.gtds.setIsSpinnerActivated(false);
  }

  private createUserAffiliate(form){
    this.email = form.login;
    localStorage.setItem(EMAIL, this.email)

    const userAffiliateCreate : UserAffiliateCreate = {
      login: form.login,
      email: form.login,
      password: form.password,
      applicationId: this.applicationId,
      title: localStorage.getItem(CIVILITY),
      lastName: localStorage.getItem(LASTNAME),
      firstName: localStorage.getItem(FIRSTNAME),
      phoneNumber: null,
      cellPhoneNumber: null
    };
    if (localStorage.getItem(PHONE_NUMBER)) {
      userAffiliateCreate.phoneNumber = localStorage.getItem(PHONE_NUMBER);
    }
    if (localStorage.getItem(CELL_PHONE_NUMBER)) {
      userAffiliateCreate.cellPhoneNumber = localStorage.getItem(CELL_PHONE_NUMBER);
    }

    this.siloApiService.createUserAffiliate(this.token, userAffiliateCreate)
      .subscribe(
        (userId) =>  this.createTermsAndConditionsAcceptances(userId),
        (error) => {
          this.treatError(error);
        }
      );
  }

  private createAdhesionRequest() {
    if (this.applicationId !== ApplicationCodeEnum.AFFILIE_APZ) {
      this.success = true;
      this.callApiIsDone = true;
      this.gtds.setIsSpinnerActivated(false)
      this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}`]);
    }
    else {
      let infoGreffeData : InfoGreffeData;
      infoGreffeData = JSON.parse(localStorage.getItem(INFO_GREFFE_DATA));

      const createAdhesionRequestRequest = new CreateAdhesionRequestRequest();
      createAdhesionRequestRequest.userId = this.userId;
      createAdhesionRequestRequest.type = this.TYPE_NEW_AFFILIATE;
      createAdhesionRequestRequest.productId = this.PRODUCT_ID_APZ;
      createAdhesionRequestRequest.compensatingId  = this.COMPENSATING_ID_BIMPLI_TR;
      if (infoGreffeData.enseigne !== null && infoGreffeData.enseigne !== undefined) {
        createAdhesionRequestRequest.name = infoGreffeData.enseigne;
      } else {
        createAdhesionRequestRequest.name = infoGreffeData.companyName;
      }
      createAdhesionRequestRequest.company = new CompanyModel();
      createAdhesionRequestRequest.company.siret = localStorage.getItem(SIRET);
      createAdhesionRequestRequest.company.companyName = infoGreffeData.companyName;
      createAdhesionRequestRequest.company.ape = infoGreffeData.activityCode;
      createAdhesionRequestRequest.chiefExecutive = new ChiefExecutive();
      createAdhesionRequestRequest.chiefExecutive.title = localStorage.getItem(CIVILITY);
      createAdhesionRequestRequest.chiefExecutive.lastName = localStorage.getItem(LASTNAME);
      createAdhesionRequestRequest.chiefExecutive.firstName = localStorage.getItem(FIRSTNAME);
      if (localStorage.getItem(PHONE_NUMBER)) {
        createAdhesionRequestRequest.chiefExecutive.phoneNumber = localStorage.getItem(PHONE_NUMBER);
      }
      if (localStorage.getItem(CELL_PHONE_NUMBER)) {
        createAdhesionRequestRequest.chiefExecutive.cellPhoneNumber = localStorage.getItem(CELL_PHONE_NUMBER);
      }
      createAdhesionRequestRequest.chiefExecutive.email = this.email;
      createAdhesionRequestRequest.address = infoGreffeData.address;
      createAdhesionRequestRequest.address.country = this.COUNTRY_DEFAULT;

      this.siloApiService.createAdhesionRequest( this.token, createAdhesionRequestRequest, this.applicationId)
        .pipe(finalize(() => this.gtds.setIsSpinnerActivated(false)))
        .subscribe(
          (userId) =>  {
            this.isLoading = false;
              this.success = true;
              this.callApiIsDone = true;
              this.router.navigate([`${RoutesEnum.CREATE_ACCOUNT_THIRD_STEP}/${this.saml}`]);
            },
          (error) => {
            this.treatError(error);
          }
        );
    }
  }

  ngOnInit() {
    this.sessionStateService.setApplicationCode(this.route);

    this.applicationCode$ = this.sessionQuery.selectedApplicationCode;

    const applicationCodeSubscription = this.sessionQuery.selectedApplicationCode
      .pipe(
        tap((applicationCode: ApplicationCodeEnum) => {
            switch (applicationCode) {
              default:
                this.applicationId = applicationCode;
            }
        })
      )
      .subscribe();

    this.subscription.add(applicationCodeSubscription);

    const samlSubscription = this.sessionQuery.selectedSaml.pipe(tap((saml) => (this.saml = saml))).subscribe();

    this.subscription.add(samlSubscription);

    const checkStepSubscription = combineLatest([this.sessionQuery.selectedApplicationCode, this.sessionQuery.selectedRegistrationStep])
      .pipe(
        take(1),
        tap(([applicationCode, registrationStep]) => {
          if (applicationCode === ApplicationCodeEnum.CESU && registrationStep !== RegistrationStepEnum.SECOND_STEP) {
            return this.router.navigate([`${RoutesEnum.LOGIN}/${this.saml}`]);
          }
        })
      )
      .subscribe();

    this.subscription.add(checkStepSubscription);
    const numCard = localStorage.getItem('creditCard');
    const expireCard = localStorage.getItem('expireDate');
    const refClient = localStorage.getItem('refCLient');

    if (numCard && numCard !== '') {
      this.gatewayService.getAccessToken().subscribe((authToken) => {
        this.token = authToken;
        this.reCaptchaV3Service.execute('GetEmployeesAction').subscribe((captcha) => {
          this.siloApiService.infosEmployees(numCard, expireCard, authToken, refClient, captcha).subscribe(
            (retour) => {
              this.success = true;
              this.callApiIsDone = true;
              this.employee = retour.items[0];
              this.employeeId = this.employee.id;
              if (retour.items[0].contacts.filter((contact) => contact.id === 'CELL_PHONE_PRO').length !== 0) {
                this.cellPhonePro = retour.items[0].contacts.filter((contact) => contact.id === 'CELL_PHONE_PRO')[0].value;
              }
              if (retour.items[0].contacts.filter((contact) => contact.id === 'EMAIL_PRO').length !== 0) {
                this.mailPro = retour.items[0].contacts.filter((contact) => contact.id === 'EMAIL_PRO')[0].value;
              }
            },
            () => {
              this.success = false;
              this.callApiIsDone = true;
              this.messageErreur = this.translate.instant('CREATION_ACCOUNT.SECOND_STEP.ERROR.UNEXPECTED');
            }
          );
        });
      });
    }
  }

  back() {
    window.history.back();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
